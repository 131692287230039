* {
  margin: 0;
}

body {
  margin: 0;
}

/* body::-webkit-scrollbar {
  background: #f1f2f2;
}

body::-webkit-scrollbar-thumb {
  background-color: #05386b;
  border: 1px solid lightgray;
  border-radius: 8px;
}

body::-webkit-scrollbar-track {
  padding: 1em;
} */
